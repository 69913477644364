import logo from "./logo.svg";
import "./login-page.css";

function LoginChoises() {
  const sellerPortal = window.location.hostname.replace("login", "seller");
  const propertyManagementPortal = window.location.hostname.replace(
    "login.",
    "",
  );

  return (
    <div className="wrapper">
      <div className="aside">
        <div className="content">
          <div className="hero">
            <img src={logo} alt="RE-OPS" />
            <p className="greeting">Welcome back!</p>
          </div>
          <p className="copyrights">© RE-OPS. 2023. All rights reserved.</p>
        </div>
      </div>
      <div className="main">
        <p className="intro">Welcome to RE-OPS!</p>
        <a
          className="button"
          href={`https://${propertyManagementPortal}/login`}
        >
          Property Management Portal
        </a>
        <a className="button" href={`https://${sellerPortal}/login`}>
          Seller Portal
        </a>
      </div>
    </div>
  );
}

export default LoginChoises;
